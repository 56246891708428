import {createStore} from 'vuex'
// @ts-ignore
import {useStoryblokApi} from '@storyblok/vue'

interface State {
    objects: Array<object>,
    loaded: boolean
}

export default createStore<State>({
    state: {
        objects: [],
        loaded: false
    },
    getters: {
        getObjects: state => {
            return state.objects
        },
        getObjectById: (state) => (id: string) => {
            return state.objects.find((object: any) => {
                return object.id === id
            }) ? state.objects.find((object: any) => {
                return object.id === id
            }) : {}
        },
        getRequiredObjectCount: state => {
            return state.objects.filter((object: any) => {
                return object.required
            }).length
        }
    },
    mutations: {
        SET_ALL_OBJECTS(state, objects) {
            state.objects = objects.map((object: any) => {
                const id = object.uuid.replace(/[a-z-]/g, '').substr(0,6)
                return {
                    uuid: object.uuid,
                    id,
                    title: object.content.title,
                    desc: object.content.description,
                    imagesource: object.content.image.filename,
                    imagealt: object.content.image.name,
                    required: object.content.required_object
                }
            })
        },
        SET_LOADED(state, status) {
            state.loaded = status
        }
    },
    actions: {
        getAllObjects({state,commit}) {
            if (state.loaded) return
            const storyblokApi = useStoryblokApi();
            let date: Number = new Date(Date.now()).getTime()
            storyblokApi.get(`${process.env.VUE_APP_API_BASE}`, {
                starts_with: 'objekte/museumsnach-2023/',
                version: process.env.VUE_APP_API_MODE,
                cv: date
            })
                .then((response: any) => {
                    commit('SET_ALL_OBJECTS', response.data.stories)
                    commit('SET_LOADED', true)
                })
                .catch((error: any) => {
                    commit('SET_LOADED', false)
                })
        }
    },
    modules: {}
})
