import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Collection from '../views/Collection.vue'
import End from '../views/End.vue'
//todo: add correct name of game
const title: string = 'Game Medizinsammlung'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: `🏥 Home - ${title}`
    }
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection,
    meta: {
      title: `🗂️ Deine Sammlung - ${title}`
    }
  },
  {
    path: '/end',
    name: 'End',
    component: End,
    meta: {
      title: `✨ Gewonnen - ${title}`
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
